@font-face {
  font-family: 'Hermes';
  src: url('../fonts/hermes.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Hermes';
  src: url('../fonts/hermes-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
